@import '../../styles/colors';

.chip {
  text-align: center;
  padding: 8px;
  background-color: $backgroundDarker;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-right: 8px;
  margin-left: 8px;
  display: inline-block;
}