@import '../../styles/colors';

.header {
  padding: 1rem 0.5rem 1rem 0.5rem;
  align-items: center;
  background: $gradientPrimary;
  display: flex;
  flex-direction: row;
  height: 78px;
  position: relative;
  margin: 0 auto;

  .label {
    color: $white;
    margin: 0 auto;
  }

  .logo {
    width: 170px;
    position: absolute;
    margin-left: 10%;
  }
}

@media (max-width: 1440px) {
  .header {
    .label {
      font-size: 26px;
    }

    .logo {
      margin-left: 5%;
    }
  }
}

@media (max-width: 575px) {
  .header {
    .label {
      font-size: 20px;
      margin-left: 10px;
    }

    .logo {
      width: 120px;
      position: relative;
      margin-left: 2%;
    }
  }
}

@media (min-width: 575px) {
  .logo {
    transform: translateY(-50%);
    top: 50%
  }
}