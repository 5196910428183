@import '../../styles/colors';

.button {
  width: auto;
  padding: 11px 25px 13px 25px;
  text-align: center;
  border-radius: 32.5px;
  cursor: pointer;
  background: $gradientSecondary;

  .label {
    color: $white;
    line-height: 19px;
  }
}