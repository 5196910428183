$primary: #f70949;
$secondary: #ebc15c;
$white: #fff;

$textSecondary: rgb(238, 196, 100);
$textGrey: rgb(148, 149, 149);
$textBlack: #000;

$backgroundDarker: rgb(234, 234, 234);
$background: rgb(248, 248, 248);
$border: rgb(204, 204, 204);
$borderTransparent: rgba(0, 0, 0, 0.1);

$gradientPrimary: linear-gradient(180deg, rgb(247, 9, 73) -30%, rgb(31, 1, 31) 102%);
$gradientSecondary: linear-gradient(180deg, rgb(255, 214, 99) -20%, rgb(200, 156, 101) 130%);
